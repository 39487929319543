import { FunctionComponent, ReactElement } from 'react';

import { useLocation } from 'react-router-dom';

import { RoutersEnum } from '../routers';

import AuthLayout from './AuthLayout';

type TProps = {
	children: ReactElement;
};

const Layout: FunctionComponent<TProps> = (props): JSX.Element => {
	const { children } = props;

	const location = useLocation();

	const compareRoute = (route, currentPath): boolean => {
		const startRoute = route.split('/')?.[1];
		const startPathName = currentPath.split('/')?.[1];
		return startRoute === startPathName;
	};

	const isAuthLayout = [
		RoutersEnum.login,
		RoutersEnum.registration,
		RoutersEnum.reset_password,
		RoutersEnum.change_password,
	].some((route) => location.pathname.includes(route));

	const isCustomLayout = [
		RoutersEnum.add_new_organization,
		RoutersEnum.invitation,
		RoutersEnum.login_okta,
	].some((route) => compareRoute(route, location.pathname));

	if (isAuthLayout) {
		return <AuthLayout>{children}</AuthLayout>;
	}

	return children;
};

export default Layout;
