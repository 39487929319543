import { Location, useLocation } from 'react-router-dom';
import { usePrevious, useUpdateEffect } from 'react-use';

const ScrollToTop = (): null => {
	const location = useLocation();
	const prevLocation: Location | undefined = usePrevious(location);
	useUpdateEffect(() => {
		if (location.pathname !== prevLocation?.pathname) {
			const scrollOptions = {
				top: 0,
				left: 0,
				passive: true,
			};

			window.scrollTo(scrollOptions);
		}
	}, [location, prevLocation]);

	return null;
};

export default ScrollToTop;
