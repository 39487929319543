import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import styles from './PageLoader.module.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const PageLoader = (): JSX.Element => {
	return (
		<div className={styles.pageLoader}>
			<Spin indicator={antIcon} />
		</div>
	);
};

export default PageLoader;
