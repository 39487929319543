import React, { FunctionComponent, ReactElement, useState } from 'react';

export type TBreadcrumbs = {
	label: string;
	path?: string;
};

export type ReturnBreadcrumbs = {
	setBreadcrumbsLoading: (value: ((prevState: boolean) => boolean) | boolean) => void;
	setBreadcrumbs: (value: ((prevState: TBreadcrumbs[]) => TBreadcrumbs[]) | TBreadcrumbs[]) => void;
	breadcrumbsLoading: boolean;
	breadcrumbs: TBreadcrumbs[];
};

const BreadcrumbsContext = React.createContext<ReturnBreadcrumbs>(undefined!);

export const useBreadcrumbs = (): ReturnBreadcrumbs => {
	const [breadcrumbs, setBreadcrumbs] = useState<TBreadcrumbs[]>([]);
	const [breadcrumbsLoading, setBreadcrumbsLoading] = useState<boolean>(false);

	return {
		breadcrumbs,
		setBreadcrumbs,
		breadcrumbsLoading,
		setBreadcrumbsLoading,
	};
};

export const BreadcrumbsProvider: FunctionComponent<{ children: ReactElement }> = ({
	children,
}) => {
	const values = useBreadcrumbs();
	return <BreadcrumbsContext.Provider value={values}>{children}</BreadcrumbsContext.Provider>;
};

export default function BreadcrumbsConsumer(): ReturnBreadcrumbs {
	const context = React.useContext(BreadcrumbsContext);
	if (context === undefined) {
		throw new Error('BreadcrumbsConsumer must be used within a BreadcrumbsProvider');
	}
	return context;
}
