import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { themeConfig } from 'themeConfig';

import App from './App';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			cacheTime: Infinity,
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<ConfigProvider theme={themeConfig}>
				<App />
				<ReactQueryDevtools initialIsOpen={false} />
			</ConfigProvider>
		</QueryClientProvider>
	</React.StrictMode>,
);
