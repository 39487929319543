import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { QueryObserverResult, RefetchOptions, useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useToggle } from 'react-use';
import axios, { API_PREFIX } from 'shared/appConfigs/axios';
import { SIDE_MENU_ITEM_TYPES } from 'shared/enums/sideMenuItemTypes';
import UserModel from 'shared/models/user.model';

import TokenService from './token.service';

export type TPhoneDetailsLogin = {
	phoneEnd: string;
	available: boolean;
};

type TApplicationModule = {
	applicationModuleType: keyof typeof SIDE_MENU_ITEM_TYPES;
	id: string;
	isLocked: boolean;
	name: string;
};

export type TUserSideMenuItem = {
	position: number;
	applicationModule?: TApplicationModule;
	id: string;
	isActive: boolean;
};

export type TUserSettings = {
	calendarId: string;
	sideMenuItems: TUserSideMenuItem[];
	isVoiceChatActive: boolean;
};

export type TOutputLogin = {
	serviceToken: string;
	expiresIn: number;
	phoneDetails: TPhoneDetailsLogin;
	sid: string | null;
};

export type TInputLogin = {
	email: string;
	password: string;
	'g-recaptcha-response': string;
};

export type TInputEmail = {
	email: string;
};

export type TInputCode = {
	totpCode: string;
	secretType: 'EMAIL' | 'SMS';
	sid: string | null;
};

export type TOutputCode = {
	accessToken: string;
	refreshToken: string;
	expiresIn: number;
};

export type TResetPassword = {
	email: string;
};

export type TCreateNewPassword = {
	password: string;
	serviceToken: string;
};

export const getMe = async (): Promise<UserModel> => {
	const { data } = await axios.get<UserModel>(`${API_PREFIX}/users/me`);
	return data;
};

export const changeEngagement = async (engagementId: string): Promise<TOutputCode> => {
	const { data } = await axios.post<TOutputCode>(`/access/engagement/${engagementId}`);
	return data;
};

export const loginUser = async (values: TInputLogin): Promise<TOutputLogin> => {
	const { data } = await axios.post<TOutputLogin>(`/auth/login`, values);
	return data;
};

export const getCodeUser = async (values: TInputCode): Promise<TOutputCode> => {
	const serviceToken = Cookies.get('serviceToken');
	const { data } = await axios.post<TOutputCode>(`/auth/login/code`, values, {
		headers: {
			Authorization: 'Bearer ' + serviceToken,
		},
	});
	return data;
};

export const getAgainCodeUser = async (
	params: { secretType: 'SMS' | 'EMAIL' } | null,
): Promise<TOutputLogin> => {
	const serviceToken = Cookies.get('serviceToken');
	const { data } = await axios.post<TOutputLogin>(
		`/access/totp/secret`,
		{},
		{
			headers: {
				Authorization: 'Bearer ' + serviceToken,
			},
			params,
		},
	);
	return data;
};

export const resetPassword = async (email: TResetPassword): Promise<string[]> => {
	const { data } = await axios.post<string[]>(`/access/password/secret`, email);
	return data;
};

export const getPasswordSecret = async (token: string): Promise<any> => {
	const { data } = await axios.get<string[]>(`/access/password/secret?secret=${token}`);
	return data;
};

export const createNewPassword = async (response: TCreateNewPassword): Promise<string[]> => {
	const { data } = await axios.post<string[]>(
		`/access/password`,
		{ password: response.password },
		{
			headers: {
				Authorization: 'Bearer ' + response.serviceToken,
			},
		},
	);
	return data;
};

export type ReturnUseAuth = {
	authed: boolean;
	logout: () => void;
	setAuthed: (initValue?: boolean) => void;
	user: UserModel | null;
	refetchUser: (options?: RefetchOptions) => Promise<QueryObserverResult<UserModel, unknown>>;
	isUserLoading: boolean;
	setLoading: (value: boolean) => void;
	setUser: Dispatch<SetStateAction<UserModel>>;
};

export const useAuth = (): ReturnUseAuth => {
	const tokenService = new TokenService();
	const isLogin = tokenService.getLocalAccessToken();

	const [user, setUser] = useState<UserModel>(new UserModel({}));
	const [isLoading, setLoading] = useToggle(true);

	const { refetch: refetchUser, isLoading: isUserLoading } = useQuery<UserModel>({
		queryFn: () => getMe(),
		queryKey: ['me'],
		enabled: false,
		// cacheTime: 0,
		onSuccess: (data) => {
			setUser(new UserModel(data));
		},
	});

	useEffect(() => {
		setLoading(isUserLoading);
	}, [isUserLoading]);

	const [authed, setAuthed] = useToggle(!!isLogin);

	const logout = (): void => {
		tokenService.removeToken();
		setAuthed(false);
		setUser(new UserModel({}));
	};

	return {
		authed,
		logout,
		setAuthed,
		user,
		refetchUser,
		isUserLoading: isLoading,
		setLoading,
		setUser,
	};
};
