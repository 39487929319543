import Cookies from 'js-cookie';

import IStorage from '../interfaces/storage.interface';
import IToken from '../interfaces/token.interface';

import TokenLocalStorageService from './token-local-storage.service';

class TokenService implements IToken {
	service: IStorage;

	constructor() {
		this.service = new TokenLocalStorageService();
	}

	getLocalRefreshToken(): string | undefined {
		return this.service.get('refreshToken');
	}

	getLocalAccessToken(): string | undefined {
		return this.service.get('accessToken');
	}

	updateLocalAccessToken(token: string): void {
		this.service.set('accessToken', token);
	}

	updateLocalRefreshToken(token: string): void {
		this.service.set('refreshToken', token);
	}

	removeToken(): void {
		this.service.delete('refreshToken');
		this.service.delete('accessToken');
		Cookies.remove('serviceToken');
		localStorage.removeItem('user');
	}
}

export default TokenService;
