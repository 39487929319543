import { lazy } from 'react';

import AuthConsumer from 'shared/guards/auth.guard';

// Public pages
const PublicStoryboardDetails = lazy(() => import('pages/PublicStoryboardDetails'));
const PublicPage = lazy(() => import('pages/PublicPage'));
const PublicNotFoundPage = lazy(() => import('pages/PublicNotFoundPage'));
const PublicOptimizationFeedback = lazy(() => import('pages/PublicOptimizationFeedback'));

// corvus pages
const ErrorPage = lazy(() => import('pages/ErrorPage'));
const InactiveSubscriptionPage = lazy(() => import('pages/InactiveSubscriptionPage'));

const AuthorizedUser = lazy(() => import('pages/AuthorizedUser'));
const Login = lazy(() => import('pages/Login'));
const Registration = lazy(() => import('pages/Registration'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const ChangePassword = lazy(() => import('pages/ChangePassword'));
const AddNewOrganization = lazy(() => import('pages/AddNewOrganization'));
const Invitation = lazy(() => import('pages/Invitation'));
const UsersManagement = lazy(() => import('pages/UsersManagement'));
const Home = lazy(() => import('pages/Home'));
const UserManagement = lazy(() => import('pages/UserManagement'));
const SubscriptionManagement = lazy(() => import('pages/SubscriptionManagement'));
const BillingsManagement = lazy(() => import('pages/BillingManagement'));
const OrganizationManagement = lazy(() => import('pages/OrganizationManagment'));
const EngagementsPage = lazy(() => import('pages/Engagements'));
const ArchivedEngagementPage = lazy(() => import('pages/Engagements/ui/ArchivedEngagementPage'));
const Optimization = lazy(() => import('pages/Optimization'));
const OptimizationAIBuild = lazy(() => import('pages/Optimization/ui/OptimizationAIBuild'));

const Projects = lazy(() => import('pages/Projects'));
const EditProject = lazy(() => import('pages/Projects/ui/EditProject'));

const StoryboardDetails = lazy(() => import('pages/StoryboardDetails'));
const Storyboards = lazy(() => import('pages/Storyboards'));
const StoryboardsManageTagsProject = lazy(
	() => import('pages/Storyboards/ui/StoryboardsManageTagsProject'),
);
const PlanTagsManagement = lazy(() => import('widgets/Plan/ui/PlanTagsManagement'));
const StoryboardsManageTagsProjectModule = lazy(
	() => import('pages/Storyboards/ui/StoryboardsManageTagsProjectModule'),
);
const StoryboardsManageTagsStoryboardModule = lazy(
	() => import('pages/Storyboards/ui/StoryboardsManageTagsStoryboardModule'),
);
const StoryboardsManageCategoriesStoryboardModule = lazy(
	() => import('pages/Storyboards/ui/StoryboardsManageCategoriesStoryboardModule'),
);
const StoryboardsManageCategoriesProjectModule = lazy(
	() => import('pages/Storyboards/ui/StoryboardsManageCategoriesProjectModule'),
);
const StoryboardsManageCategoriesProject = lazy(
	() => import('pages/Storyboards/ui/StoryboardsManageCategoriesProject'),
);
const RetrospectivesPage = lazy(() => import('pages/Retrospectives'));
const EditActionPlanPage = lazy(() => import('pages/ActionPlan/ui/EditActionPlanPage'));
const ArchivedActionPlanPage = lazy(() => import('pages/ActionPlan/ui/ArchivedActionPlanPage'));
const ActionPlanTagsManagement = lazy(
	() => import('pages/ActionPlan/ui/ActionPlanTagsManagementPage'),
);
const ActionPlanCategoriesManagement = lazy(
	() => import('pages/ActionPlan/ui/ActionPlanCategoriesManagementPage'),
);
const AddLessonsLearned = lazy(() => import('pages/Retrospectives/ui/AddLessonsLearned'));
const EditLessonsLearned = lazy(() => import('pages/Retrospectives/ui/EditLessonsLearned'));
const DigitalWhiteboards = lazy(() => import('pages/DigitalWhiteboards'));
const EditDigitalWhiteboards = lazy(
	() => import('pages/DigitalWhiteboards/ui/EditDigitalWhiteboards'),
);
const OperatingModel = lazy(() => import('pages/OperatingModel'));
const EditOperatingModel = lazy(() => import('pages/OperatingModel/ui/EditOperatingModel'));
const Forecast = lazy(() => import('pages/Forecast'));
const ArchivedForecastPage = lazy(() => import('pages/Forecast/ui/ArchivedForecastPage'));
const ForecastTagsManagement = lazy(() => import('pages/Forecast/ui/ForecastTagsManagement'));
const ForecastCategoriesManagement = lazy(
	() => import('pages/Forecast/ui/ForecastCategoriesManagement'),
);
const ForecastDetailsPage = lazy(() => import('pages/Forecast/ui/ForecastDetailsPage'));
const PlanPage = lazy(() => import('pages/Plan'));
const ActionPlanPage = lazy(() => import('pages/ActionPlan'));
const AIBuildPlanPage = lazy(() => import('pages/Plan/ui/AIBuildPlanPage'));
const ArchivedPlanPage = lazy(() => import('pages/Plan/ui/ArchivedPlanPage'));
const FileExplorerPage = lazy(() => import('pages/FileExplorer'));
const NotePage = lazy(() => import('pages/Notes/ui/AddNewNote'));
const NotesPage = lazy(() => import('pages/Notes'));
const EditNote = lazy(() => import('pages/Notes/ui/EditNote'));

const IndustryReportsPage = lazy(() => import('pages/IndustryReports'));
const ProcessReportsPage = lazy(() => import('pages/ProcessReports'));

export type TRoutes = {
	name: string;
	path: string;
	Component: any;
	isAuth?: boolean;
	isHasAccess?: boolean;
	children?: TRoutes[];
	index?: boolean;
};

interface IDynamicObject {
	[key: string]: string;
}

export enum RoutersEnum {
	// error-page
	error_page = 'error-page',

	//Inactive subscription
	inactive_subscription = 'inactive-subscription',

	login = '/login',
	login_okta = '/oauth2/login/okta',
	registration = '/registration',
	reset_password = '/reset-password',
	change_password = '/change-password',
	add_new_user = '/add-new-user',
	add_new_organization = '/add-new-organization',
	invitation = '/invitation/:id',
	authorized_user = '/:engId/',
	// users
	users_management = 'users',
	users_management_edit = ':id',
	//subscriptions
	subscriptions_management = 'subscriptions',
	// billings
	billings_management = 'billings',
	// organizations
	organizations_management = 'organizations',
	organizations_management_edit = ':id',
	//engagement
	engagements = 'engagements',
	engagements_archived = 'archived',
	//plans
	plan = 'plan',
	plan_edit = ':planId/plan-document/:storyboardId',
	plan_ai_build = ':planId/ai_build',
	plan_tags_management = 'tags',
	plan_archived = 'archived',
	//action_plan
	action_plan = 'action-plan',
	action_plan_archived = 'archived',
	action_plan_edit = ':id',
	action_plan_tags_management = 'tags',
	action_plan_categories_management = 'categories',

	//  project_portfolio - projects
	project_portfolio_projects = 'projects',
	project_portfolio_projects_storyboard_details = 'portfolioGuidance/:storyboardId',
	project_portfolio_projects_tags_management = 'tags',
	project_portfolio_projects_categories_management = 'categories',
	project_portfolio_projects_edit_tags_management = 'tags',
	project_portfolio_projects_edit_categories_management = 'categories',
	project_portfolio_projects_edit = ':id',
	project_portfolio_projects_edit_storyboard_details = 'workProducts/:storyboardId',
	// optimization
	optimization = 'optimization',
	optimization_row_data = ':optId/optimization-row-data/:storyboardId',
	optimization_ai_report = ':optId/optimization-ai-report/:storyboardId',
	optimization_ai_build = ':optId/ai-build',
	optimization_cards = ':optId',
	// retrospectives
	retrospectives = 'retrospectives',
	retrospectives_lessons_learned_add = 'new-lessons-learned',
	retrospectives_lessons_learned_edit = 'lessons-learned/:id',
	// actionPlan
	actionPlan_tags_management = 'tags',
	actionPlan_categorys_management = 'categories',
	// storyboards
	storyboards = 'storyboards',
	storyboard_details = ':storyboardId',
	storyboards_tags_management = 'tags',
	storyboards_categories_management = 'categories',
	// home
	home = 'home',
	// digital-whiteboards
	digital_whiteboards = 'digital-whiteboards',
	digital_whiteboards_edit = ':id',
	// operating-model
	operating_model = 'operating-model',
	operating_model_edit = ':id',
	// forecast
	forecast = 'forecast',
	forecast_details = ':id',
	forecast_archived = 'archived',
	forecast_tags_management = 'tags',
	forecast_categories_management = 'categories',
	// file-exporter
	file_explorer = 'file-exporter',
	// notes
	notes = 'notes',
	note_add = 'new-note',
	note_edit = ':id',
	//Industry Reports
	industry_reports = 'industry-reports',
	industry_reports_details = ':storyboardId',
	//process Reports
	process_reports = 'process-reports',
	process_reports_details = ':storyboardId',

	// public pages
	public = 'public',
	public_not_found = 'not-found',
	public_storyboard = 'storyboard/:hash',
	public_optimization_feedback = 'optimization-feedback/:hash',
}

export enum RoutersFullpathEnum {
	// error-page
	error_page = 'error-page',

	//Inactive subscription
	inactive_subscription = '/inactive-subscription',

	login = '/login',
	login_okta = '/oauth2/login/okta',
	registration = '/registration',
	reset_password = '/reset-password',
	change_password = '/change-password',
	change_password_secret = '/change-password/:token',
	add_new_user = '/add-new-user',
	add_new_organization = '/add-new-organization',
	invitation = '/invitation/:id',
	authorized_user = '/:engId/',
	// users
	users_management = 'users',
	users_management_edit = 'users/:id',
	// subscriptions
	subscriptions_management = 'subscriptions',
	// billings
	billings_management = 'billings',
	// organizations
	organizations_management = 'organizations',
	organizations_management_edit = 'organizations/:id',
	// engagements
	engagements = 'engagements',
	engagements_archived = 'engagements/archived',
	// plans
	plan = 'plan',
	plan_add = 'plan/new-plan',
	plan_edit = 'plan/:planId/plan-document/:storyboardId',
	plan_ai_build = 'plan/:planId/ai-build',
	plan_tags_management = 'plan/tags',
	plan_archived = 'plan/archived',
	//action_plan
	action_plan = 'action-plan',
	action_plan_archived = 'action-plan/archived',
	action_plan_edit = 'action-plan/:id',
	action_plan_tags_management = 'action-plan/tags',
	action_plan_categories_management = 'action-plan/categories',
	// project-portfolio - projects
	project_portfolio_projects = 'projects',
	project_portfolio_projects_storyboard_details = 'projects/portfolioGuidance/:storyboardId',
	project_portfolio_projects_edit = 'projects/:id',
	project_portfolio_projects_tags_management = 'projects/tags',
	project_portfolio_projects_categories_management = 'projects/categories',
	project_portfolio_projects_edit_tags_management = 'projects/:id/tags',
	project_portfolio_projects_edit_categories_management = 'projects/:id/categories',
	project_portfolio_projects_edit_storyboard_details = 'projects/:id/workProducts/:storyboardId',
	// optimization
	optimization = 'optimization',
	optimization_ai_build = 'optimization/:optId/ai-build',
	optimization_row_data = 'optimization/:optId/optimization-row-data/:storyboardId',
	optimization_ai_report = 'optimization/:optId/optimization-ai-report/:storyboardId',
	optimization_cards = 'optimization/:optId',
	// retrospectives
	retrospectives = 'retrospectives',
	retrospectives_lessons_learned_add = 'retrospectives/new-lessons-learned',
	retrospectives_lessons_learned_edit = 'retrospectives/lessons-learned/:id',
	// actionPlan
	actionPlan_tags_management = 'actionPlan/tags',
	actionPlan_categorys_management = 'actionPlan/categories',
	// storyboards
	storyboards = 'storyboards',
	storyboard_details = 'storyboards/:storyboardId',
	storyboards_tags_management = 'storyboards/tags',
	storyboards_categories_management = 'storyboards/categories',
	// home
	home = 'home',
	// digital-whiteboards
	digital_whiteboards = 'digital-whiteboards',
	digital_whiteboards_edit = 'digital-whiteboards/:id',
	// operating-model
	operating_model = 'operating-model',
	operating_model_edit = 'operating-model/:id',
	// forecast
	forecast = 'forecast',
	forecast_details = 'forecast/:id',
	forecast_archived = 'forecast/archived',
	forecast_tags_management = 'forecast/tags',
	forecast_categories_management = 'forecast/categories',
	// file-exporter
	file_explorer = 'file-exporter',
	//notes
	notes = 'notes',
	note_add = 'notes/new-note',
	note_edit = 'notes/:id',
	//IndustryReports
	industry_reports = 'industry-reports',
	industry_reports_details = 'industry-reports/:storyboardId',
	//ProcessReports
	process_reports = 'process-reports',
	process_reports_details = 'process-reports/:storyboardId',

	// public pages
	public = 'public',
	public_not_found = 'public/not-found',
	public_storyboard = 'public/storyboard/:hash',
	public_optimization_feedback = 'public/optimization-feedback/:hash',
}

export const useGetPublicRoutes = (): { publicRoutes: TRoutes[] } => {
	const publicRoutes: TRoutes[] = [
		{
			name: 'public',
			path: RoutersEnum.public,
			Component: PublicPage,
			isAuth: false,
			isHasAccess: true,
			children: [
				{
					name: 'public_not_found',
					path: RoutersEnum.public_not_found,
					Component: PublicNotFoundPage,
					isAuth: false,
					isHasAccess: true,
				},
				{
					name: 'public_storyboard',
					path: RoutersEnum.public_storyboard,
					Component: PublicStoryboardDetails,
					isAuth: false,
					isHasAccess: true,
				},
				{
					name: 'public_optimization_feedback',
					path: RoutersEnum.public_optimization_feedback,
					Component: PublicOptimizationFeedback,
					isAuth: false,
					isHasAccess: true,
				},
			],
		},
	];

	return { publicRoutes };
};

export const useGetRoutes = (): {
	routes: TRoutes[];
	routesMap: IDynamicObject;
} => {
	const { user } = AuthConsumer();

	const routes: TRoutes[] = [
		{
			name: 'error_page',
			path: RoutersFullpathEnum.error_page,
			Component: ErrorPage,
			isAuth: true,
			isHasAccess: true,
		},
		{
			name: 'inactive_subscription',
			path: RoutersFullpathEnum.inactive_subscription,
			Component: InactiveSubscriptionPage,
			isAuth: true,
			isHasAccess: true,
		},
		{
			name: 'login',
			path: RoutersFullpathEnum.login,
			Component: Login,
			isAuth: false,
			isHasAccess: true,
		},
		{
			name: 'registration',
			path: RoutersFullpathEnum.registration,
			Component: Registration,
			isAuth: false,
			isHasAccess: true,
		},
		{
			name: 'invitation',
			path: RoutersFullpathEnum.invitation,
			Component: Invitation,
			isAuth: false,
			isHasAccess: true,
		},
		{
			name: 'reset_password',
			path: RoutersFullpathEnum.reset_password,
			Component: ResetPassword,
			isAuth: false,
			isHasAccess: true,
		},
		{
			name: 'change_password',
			path: RoutersFullpathEnum.change_password,
			Component: ChangePassword,
			isAuth: false,
			isHasAccess: true,
		},
		{
			name: 'change_password_secret',
			path: RoutersFullpathEnum.change_password_secret,
			Component: ChangePassword,
			isAuth: false,
			isHasAccess: true,
		},
		{
			name: 'new_organization',
			path: RoutersFullpathEnum.add_new_organization,
			Component: AddNewOrganization,
			isAuth: true,
			isHasAccess: true,
		},
		{
			name: 'authorized_user',
			path: RoutersFullpathEnum.authorized_user,
			Component: AuthorizedUser,
			isAuth: true,
			isHasAccess: true,
			children: [
				{
					name: 'home',
					path: RoutersFullpathEnum.home,
					Component: Home,
					isAuth: true,
					isHasAccess: user?.isCanBeViewHomePage,
				},
				{
					name: 'subscriptions_management',
					path: RoutersFullpathEnum.subscriptions_management,
					Component: SubscriptionManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewBillingManagement,
				},
				{
					name: 'billings_management',
					path: RoutersFullpathEnum.billings_management,
					Component: BillingsManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewBillingManagement,
				},
				{
					name: 'users_management',
					path: RoutersFullpathEnum.users_management,
					Component: UsersManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewUserManagement,
				},
				{
					name: 'users_management_edit',
					path: RoutersFullpathEnum.users_management_edit,
					Component: UserManagement,
					isAuth: true,
					isHasAccess: true,
				},

				{
					name: 'organizations_management_edit',
					path: RoutersFullpathEnum.organizations_management_edit,
					Component: OrganizationManagement,
					isAuth: true,
					isHasAccess: true,
				},

				{
					name: 'engagements',
					path: RoutersFullpathEnum.engagements,
					Component: EngagementsPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewEngagementManagement,
				},
				{
					name: 'engagements_archived',
					path: RoutersFullpathEnum.engagements_archived,
					Component: ArchivedEngagementPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewEngagementManagement,
				},
				{
					name: 'optimization',
					path: RoutersFullpathEnum.optimization,
					Component: Optimization,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOptimizationPage,
				},
				{
					name: 'optimization_ai_build',
					path: RoutersFullpathEnum.optimization_ai_build,
					Component: OptimizationAIBuild,
					isAuth: true,
					isHasAccess: user?.isCanBeCreateOptimizationPage,
				},
				{
					name: 'optimization_ai_report',
					path: RoutersFullpathEnum.optimization_ai_report,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOptimizationPage,
				},
				{
					name: 'optimization_row_data',
					path: RoutersFullpathEnum.optimization_row_data,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOptimizationPage,
				},
				{
					name: 'project_portfolio_projects',
					path: RoutersFullpathEnum.project_portfolio_projects,
					Component: Projects,
					isAuth: true,
					isHasAccess:
						user?.isCanBeViewPortfoliosPage ||
						user?.isCanBeViewProgramsPage ||
						user?.isCanBeViewProjectsPage,
				},
				{
					name: 'project_portfolio_projects_edit',
					path: RoutersFullpathEnum.project_portfolio_projects_edit,
					Component: EditProject,
					isAuth: true,
					isHasAccess: user?.isCanBeViewProjectsPage,
				},
				{
					name: 'project_portfolio_projects_storyboard_details',
					path: RoutersFullpathEnum.project_portfolio_projects_storyboard_details,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPortfolioGuidancePage,
				},
				{
					name: 'project_portfolio_projects_edit_storyboard_details',
					path: RoutersFullpathEnum.project_portfolio_projects_edit_storyboard_details,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewProjectsPage,
				},
				{
					name: 'retrospectives',
					path: RoutersFullpathEnum.retrospectives,
					Component: RetrospectivesPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewReviewSolutionsPage || user?.isCanBeViewLessonLearnedPage,
				},
				{
					name: 'retrospectives_lessons_learned_add',
					path: RoutersFullpathEnum.retrospectives_lessons_learned_add,
					Component: AddLessonsLearned,
					isAuth: true,
					isHasAccess: user?.isCanBeCreateLessonLearnedPage,
				},
				{
					name: 'retrospectives_lessons_learned_edit',
					path: RoutersFullpathEnum.retrospectives_lessons_learned_edit,
					Component: EditLessonsLearned,
					isAuth: true,
					isHasAccess: user?.isCanBeEditLessonLearnedPage,
				},
				{
					name: 'action_plan',
					path: RoutersFullpathEnum.action_plan,
					Component: ActionPlanPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewActionPlansPage,
				},
				{
					name: 'action_plan_edit',
					path: RoutersFullpathEnum.action_plan_edit,
					Component: EditActionPlanPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewActionPlansPage,
				},
				{
					name: 'action_plan_archived',
					path: RoutersFullpathEnum.action_plan_archived,
					Component: ArchivedActionPlanPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewActionPlansPage,
				},
				{
					name: 'action_plan_tags_management',
					path: RoutersFullpathEnum.action_plan_tags_management,
					Component: ActionPlanTagsManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewActionPlansPage,
				},
				{
					name: 'action_plan_categories_management',
					path: RoutersFullpathEnum.action_plan_categories_management,
					Component: ActionPlanCategoriesManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewActionPlansPage,
				},
				{
					name: 'storyboards',
					path: RoutersFullpathEnum.storyboards,
					Component: Storyboards,
					isAuth: true,
					isHasAccess: user?.isCanBeViewStoryBoards,
				},
				{
					name: 'storyboards_tags_management',
					path: RoutersFullpathEnum.storyboards_tags_management,
					Component: StoryboardsManageTagsStoryboardModule,
					isAuth: true,
					isHasAccess: user?.isCanBeViewStoryBoards,
				},
				{
					name: 'storyboards_categories_management',
					path: RoutersFullpathEnum.storyboards_categories_management,
					Component: StoryboardsManageCategoriesStoryboardModule,
					isAuth: true,
					isHasAccess: user?.isCanBeViewStoryBoards,
				},
				{
					name: 'project_portfolio_projects_edit_tags_management',
					path: RoutersFullpathEnum.project_portfolio_projects_edit_tags_management,
					Component: StoryboardsManageTagsProject,
					isAuth: true,
					isHasAccess: user?.isCanBeViewProjectsPage,
				},
				{
					name: 'project_portfolio_projects_edit_categories_management',
					path: RoutersFullpathEnum.project_portfolio_projects_edit_categories_management,
					Component: StoryboardsManageCategoriesProject,
					isAuth: true,
					isHasAccess: user?.isCanBeViewProjectsPage,
				},
				{
					name: 'project_portfolio_projects_tags_management',
					path: RoutersFullpathEnum.project_portfolio_projects_tags_management,
					Component: StoryboardsManageTagsProjectModule,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPortfolioGuidancePage,
				},
				{
					name: 'project_portfolio_projects_categories_management',
					path: RoutersFullpathEnum.project_portfolio_projects_categories_management,
					Component: StoryboardsManageCategoriesProjectModule,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPortfolioGuidancePage,
				},
				{
					name: 'storyboard_details',
					path: RoutersFullpathEnum.storyboard_details,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewStoryBoards,
				},
				{
					name: 'digital_whiteboards',
					path: RoutersFullpathEnum.digital_whiteboards,
					Component: DigitalWhiteboards,
					isAuth: true,
					isHasAccess: user?.isCanBeViewDigitalWhiteboardsPage,
				},
				{
					name: 'digital_whiteboards_edit',
					path: RoutersFullpathEnum.digital_whiteboards_edit,
					Component: EditDigitalWhiteboards,
					isAuth: true,
					isHasAccess: user?.isCanBeViewDigitalWhiteboardsPage,
				},
				{
					name: 'operating_model',
					path: RoutersFullpathEnum.operating_model,
					Component: OperatingModel,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOperatingModelPage,
				},
				{
					name: 'operating_model_edit',
					path: RoutersFullpathEnum.operating_model_edit,
					Component: EditOperatingModel,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOperatingModelPage,
				},
				{
					name: 'forecast',
					path: RoutersFullpathEnum.forecast,
					Component: Forecast,
					isAuth: true,
					isHasAccess: user?.isCanBeViewForecastPage,
				},
				{
					name: 'forecast_archived',
					path: RoutersFullpathEnum.forecast_archived,
					Component: ArchivedForecastPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewForecastPage,
				},
				{
					name: 'forecast_tags_management',
					path: RoutersFullpathEnum.forecast_tags_management,
					Component: ForecastTagsManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewForecastPage,
				},
				{
					name: 'forecast_categories_management',
					path: RoutersFullpathEnum.forecast_categories_management,
					Component: ForecastCategoriesManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewForecastPage,
				},
				{
					name: 'forecast_details',
					path: RoutersFullpathEnum.forecast_details,
					Component: ForecastDetailsPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewForecastPage,
				},
				{
					name: 'plan',
					path: RoutersFullpathEnum.plan,
					Component: PlanPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPlanPage,
				},
				{
					name: 'plan_archived',
					path: RoutersFullpathEnum.plan_archived,
					Component: ArchivedPlanPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPlanPage,
				},
				{
					name: 'plan_ai_build',
					path: RoutersFullpathEnum.plan_ai_build,
					Component: AIBuildPlanPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPlanPage,
				},
				{
					name: 'plan_edit',
					path: RoutersFullpathEnum.plan_edit,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPlanPage,
				},
				{
					name: 'plan_tags_management',
					path: RoutersFullpathEnum.plan_tags_management,
					Component: PlanTagsManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPlanPage,
				},
				{
					name: 'file_explorer',
					path: RoutersFullpathEnum.file_explorer,
					Component: FileExplorerPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewFileExplorerPage,
				},
				{
					name: 'notes',
					path: RoutersFullpathEnum.notes,
					Component: NotesPage,
					isAuth: true,
					isHasAccess: true,
				},
				{
					name: 'note_add',
					path: RoutersFullpathEnum.note_add,
					Component: NotePage,
					isAuth: true,
					isHasAccess: true,
				},
				{
					name: 'note_edit',
					path: RoutersFullpathEnum.note_edit,
					Component: EditNote,
					isAuth: true,
					isHasAccess: true,
				},
				{
					name: 'industry_reports',
					path: RoutersFullpathEnum.industry_reports,
					Component: IndustryReportsPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewIndustryReportsPage,
				},
				{
					name: 'industry_reports_details',
					path: RoutersFullpathEnum.industry_reports_details,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewIndustryReportsPage,
				},
				{
					name: 'process_reports',
					path: RoutersFullpathEnum.process_reports,
					Component: ProcessReportsPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewProcessReportsPage,
				},
				{
					name: 'process_reports_details',
					path: RoutersFullpathEnum.process_reports_details,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewProcessReportsPage,
				},
			],
		},
	];

	const routesMap: IDynamicObject = {};

	routes.forEach((route) => {
		if (route.hasOwnProperty('name')) {
			routesMap[route.name] = route.path;
		}
	});

	return { routes, routesMap };
};

function urlBuilder(
	name: RoutersEnum | RoutersFullpathEnum | string,
	params: IDynamicObject,
	searchParam?: string,
): string | null {
	if (!name) {
		return null;
	}

	let url = ''; // news/:id

	for (const key in params) {
		url = name.replace(`:${key}`, params[key]);
	}

	if (searchParam) {
		return `${url}${searchParam}`;
	}

	return url;
}

export const concatUrl = (values: string[], withStartSlash = false): string => {
	const url = values.join('/');
	if (withStartSlash) {
		return `/${url}`;
	}
	return url;
};

export { urlBuilder };
