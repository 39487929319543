export enum SIDE_MENU_ITEM_TYPES {
	STORYBOARD = 'STORYBOARD',
	PLAN = 'PLAN',
	ACTION_PLAN = 'ACTION_PLAN',
	FORECAST = 'FORECAST',
	OPERATING_MODEL = 'OPERATING_MODEL',
	PROJECTS = 'PROJECTS',
	OPTIMIZATION = 'OPTIMIZATION',
	DIGITAL_WHITEBOARDS = 'DIGITAL_WHITEBOARDS',
	RETROSPECTIVES = 'RETROSPECTIVES',
	FILE_EXPLORER = 'FILE_EXPLORER',
	INDUSTRY_REPORTS = 'INDUSTRY_REPORTS',
	PROCESS_REPORTS = 'PROCESS_REPORTS',
}
